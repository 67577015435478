<template>
  <div>
    <v-card class="mx-auto" max-width="800">
      <v-card-title>
        <editable-avatar class="avatar"
          :image="profile.midThumbnail ? profile.midThumbnail : profile.image"
          :onImageSelected="onImageSelected"
        />
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="formValid">
          <v-text-field
            label="Business Name"
            v-model="companyName"
            :rules="[v => !!v || 'Business Name is required']"
          ></v-text-field>
          <v-text-field
            label="Contact Person"
            v-model="contactPerson"
            :rules="[v => !!v || 'Contact Person is required']"
          ></v-text-field>
          <v-text-field
            label="Contact Title"
            v-model="contactTitle"
            :rules="[v => !!v || 'Contact Title is required']"
          ></v-text-field>
          <v-text-field
            label="Email"
            v-model="email"
            :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid']"
          ></v-text-field>
          <v-text-field
            label="Phone Number"
            v-model="phoneNumber"
            :rules="[v => !!v || 'Phone Number is required']"
          ></v-text-field>
          <v-text-field
            label="Address"
            v-model="address"
            :rules="[v => !!v || 'Address is required']"
          ></v-text-field>
          <v-text-field
            label="City"
            v-model="city"
            :rules="[v => !!v || 'City is required']"
          ></v-text-field>
          <div>
            <label for="">State</label>
                <v-select
            label="State"
            v-model="state"
            :items="stateOptions"
            :rules="[v => !!v || 'State is required']"
            single-line
            solo
            outlined
            ></v-select>
          </div>
          <v-text-field
            label="Zip Code"
            v-model="zip"
            :rules="[v => !!v || 'Zip Code is required']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!isFormValid" color="primary" @click="updateProfile">Update Profile</v-btn>
      </v-card-actions>
    </v-card>
     <v-snackbar v-model="snackbar" :timeout="3000">
        {{ message }}
      </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";

export default {
  components: {
    EditableAvatar,
  },
  data() {
    return {
      companyName: '',
      email: '',
      contactPerson: '',
      contactTitle: '',
      phoneNumber: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      selectedImage: null,
      formValid: false,
      snackbar: false,
      message: null,
       stateOptions: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "AS",
        "DC",
        "FM",
        "GU",
        "MH",
        "MP",
        "PW",
        "PR",
        "VI"
      ]
    };
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
    isFormValid() {
      return this.companyName && this.contactPerson && this.contactTitle && this.email && 
             this.phoneNumber && this.address && this.city && this.state && this.zip;
    }
  },
  watch: {
    profile: {
      handler(newProfile) {
        if (newProfile) {
          this.companyName = newProfile.name || '';
          this.contactPerson = newProfile.contact_person || '';
          this.contactTitle = newProfile.contact_title || '';
          this.email = newProfile.email || '';
          this.phoneNumber = newProfile.phone || '';
          this.address = newProfile.address || '';
          this.city = newProfile.city || '';
          this.state = newProfile.state || '';
          this.zip = newProfile.zip || '';
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("company", ["updateCompany"]),
    onImageSelected(image) {
      this.selectedImage = image;
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    async updateProfile() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return; // If form is invalid, stop execution

      const formData = new FormData();
      formData.append('name', this.companyName);
      formData.append('email', this.email);
      formData.append('address', this.address);
      formData.append('city', this.city);
      formData.append('state', this.state);
      formData.append('zip', this.zip);
      formData.append('phone', this.phoneNumber);
      formData.append('contact_person', this.contactPerson);
      formData.append('contact_title', this.contactTitle);
      if (this.selectedImage) {
        formData.append('image', this.selectedImage);
      }

      try {
        await this.updateCompany(formData);
        this.snackbar = true;
        this.message = "Profile updated successfully!";
      } catch (error) {
        this.snackbar = true;
        this.message = error;
        console.error("Error updating profile:", error);
      }
    }
  }
};
</script>

<style scoped>
.v-card {
  padding: 20px;
  margin-top: 200px;
}
.avatar {
  margin: 0 auto;
}
</style>
